<template>
    <v-layout column fill-height>
        <v-flex shrink>
            <w-btn color="" flat small icon @click="goToPage(teamListPage)">
              <w-icon>chevron_left</w-icon><span style="margin-top: 0.2rem">{{ $t('holding.admin-team.go_back_to_list') }}</span>
            </w-btn>
        </v-flex>
        <v-divider />
        <v-flex text-xs-center shrink>
            <w-tabs centered  icons-and-text :value="activePage">
                <v-tabs-slider color="primary" />
                <v-tab v-for="(page, index) in pages" :key="index" @click="goToPage(page)">
                    {{ page.title }}
                    <v-icon>{{ page.icon }}</v-icon>
                </v-tab>
            </w-tabs>
        </v-flex>
         <w-flex v-if="pageLoading">
			<w-layout  align-center fill-height justify-center>
				<v-progress-circular color="primary" indeterminate size="50" width="6" />
			</w-layout>
		</w-flex>
        <v-flex v-else scroll-y>
            <router-view />
        </v-flex>
    </v-layout>
</template>

<script>
import HoldingUserModuleGuard from '@/mixins/ModulesGuards/Holding/HoldingUserModuleGuard'
export default {
    name: 'HoldingUserSettings',
    mixins: [HoldingUserModuleGuard],
    inject: ['holdingContext'],
    data: function () {
        return {
            pageLoading: true,
            pages: [
                { 
                    route: 'holding-team-user-general',
                    title: this.$t('holding.team-manager.pages.general'),
                    icon: 'person' 
                },
                {
                    route: 'holding-team-user-accounting-firm-assignment',
                    title: this.$t('holding.team-manager.pages.accounting'),
                    icon: 'spoke' 
                },
                {
                    route: 'holding-team-user-vendors',
                    title: this.$t('holding.team-manager.pages.vendors'),
                    icon: 'domain' 
                },
                {
                    route: 'holding-team-user-themes',
                    title: this.$t('holding.team-manager.pages.themes'),
                    icon: 'bookmarks' 
                },
            
            ],
            teamListPage: {route: 'holding-team'},
        }
    },
    computed: {
        activePage: function () {
            const routeName = this.$route.name

            const result = this.pages.findIndex(page => {
                return page.route == routeName
            })

            return result == -1 ? 0 : result
        },
        holdingId: function () {
			return this.holdingContext?.holding_id
		},
        editedUserId: function () {
			return this.$route.params.userId
		},
    },
    mounted: function () {
        this.loadUserInformations()
        // Ugly hack to show the arrow on the tab slider
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'))
		}, 400)
    },
    methods: {
        loadUserInformations: function () {
            this.pageLoading = true
			this.service.findHoldingUser(this.holdingId, this.editedUserId, {count_access_to_vendors: true}).then(() => {
                this.pageLoading = false
			})
		},
        goToPage: function (page) {
            const currentParams = this.$route.params
            this.appService.goTo({
                name: page.route,
                params: currentParams
            })
        },
    }
}
</script>